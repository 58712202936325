import {
  Box,
  Card,
  Chip,
  Divider,
  Grid,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllFavGpts, getAllGpts } from "../../store/bot/actions";
import GptsStoreSkeleton from "./GptsStoreSkeleton";
import { debounce } from "lodash";
import { httpMushPro } from "../../services/http-common";
import { useMutation } from "react-query";
import {
  Button,
  Drawer,
  IconButton,
  Option,
  Select,
  Input,
  Textarea,
} from "@material-tailwind/react";
import { allExpertise, theraupatic_approaches } from "../../utils/profileData";

const GptsStore = () => {
  const { allGpts, favGpts } = useSelector((state: any) => state.bot);
  const [selectedGpt, setSelectedGpt] = useState(allGpts);
  const [allSearchGpt, setAllSearchGpt] = useState([]);
  const dispatch = useDispatch();
  const [openAdSearch, setOpenAdSearch] = useState(false);
  const [search, setSearch] = useState({
    botName: "",
    LGBTQ: "",
    primaryDomain: "",
    experience: "",
    expertise: "",
    theraupatic_approaches: "",
  });

  const searchGPTsByParam = useMutation(
    () => {
      return httpMushPro.post(`user/bots/searchBots?cp=1&ps=20`, search);
    },
    {
      onSuccess: ({ data }) => {
        setAllSearchGpt(data.records);
        setSelectedGpt(data.records);
        setOpenAdSearch(false);
        setSearch({
          botName: "",
          LGBTQ: "",
          primaryDomain: "",
          experience: "",
          expertise: "",
          theraupatic_approaches: "",
        });
      },
      onError: ({ error }) => {
        console.error(error.response.data.message);
        setOpenAdSearch(false);
      },
    }
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetSearchByName = useCallback(
    debounce((text) => searchGPTsByParam.mutate(), 1000),
    []
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch((prev: any) => ({ ...prev, botName: e.target.value }));
    debouncedSetSearchByName(e.target.value);
  };

  const handleSubmitSearch = () => {
    searchGPTsByParam.mutate();
  };

  const handleSearchChange = (name: string, value: any) => {
    setSearch((prev: any) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    !allGpts.length && dispatch(getAllGpts());
    !favGpts.length && dispatch(getAllFavGpts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    allGpts.length && setSelectedGpt(allGpts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allGpts]);
  return (
    <>
      <Box
        id="scrollableDiv"
        className="max-h-[calc(100vh-200px)] md:max-h-[calc(100vh-190px)] lg:max-h-[calc(100vh-190px)] xl:max-h-[calc(100vh-190px)] "
        sx={{
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <div className="mx-auto w-full max-w-5xl text-center my-4">
          <h5 className="text-xl font-semibold md:text-2xl">
            Find the Perfect Coach!
          </h5>
          <p className="w-full mt-2 text-zinc-600">
            {" "}
            Our GPT assistant coaches can answer entheo-related questions, do
            intakes, and book appointments free!
          </p>
          <div
            className="group relative rounded-xl z-20 mb-6 mt-2 flex-grow shadow-[0px_10px_10px_-6px_rgba(0,0,0,0.04)]"
            data-headlessui-state=""
          >
            <div className="relative">
              <div className="flex relative gap-2">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="none"
                    viewBox="0 0 24 24"
                    className="icon-md z-50 absolute cursor-pointer top-0 h-full text-token-text-tertiary right-3"
                    onClick={() => searchGPTsByParam.mutate()}
                  >
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M10.75 4.25a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13m-8.5 6.5a8.5 8.5 0 1 1 15.176 5.262l4.031 4.03a1 1 0 0 1-1.414 1.415l-4.031-4.031A8.5 8.5 0 0 1 2.25 10.75"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>

                <input
                  className="z-10 w-full rounded-xl border border-token-border-light bg-token-main-surface-primary py-2 font-normal outline-0 delay-100 md:h-14 h-12 text-base pl-5 pr-12"
                  placeholder="Search GPT by name"
                  value={search.botName}
                  onChange={(e) => {
                    handleSearch(e);
                  }}
                />
              </div>
              <div className="flex justify-end items-center">
                <Button
                  size="sm"
                  onClick={() => setOpenAdSearch(true)}
                  variant="text"
                  className="text-[#000080]"
                >
                  Advance Search
                </Button>
              </div>
              {/* dropdown by searcted Item */}
              {false && (
                <div
                  className="absolute !bg-white  top-[calc(100%-10px)] w-full overflow-y-auto rounded-lg rounded-t-none border border-t-0 border-token-border-light bg-token-main-surface-primary px-3 py-2 opacity-100 translate-y-0"
                  id="headlessui-popover-panel-:r2n:"
                  tabIndex={-1}
                  data-headlessui-state=""
                >
                  {allSearchGpt.length !== 0 ? (
                    <>
                      {allSearchGpt.map((gpt: any) => (
                        <Link
                          to={`#`}
                          key={gpt.id}
                          className=" cursor-pointer !bg-white group mx-2 flex items-center gap-3 rounded-lg px-3 py-2 hover:bg-gray-50 dark:hover:bg-white/10"
                        >
                          <div className="h-10 w-10 shrink-0">
                            <div className=" overflow-hidden rounded-full">
                              <img
                                src={gpt.iconUrl}
                                className="h-full w-full  flex bg-white items-center justify-center rounded-full border-2 border-dashed border-token-border-medium"
                                alt={gpt.botName}
                                width="130"
                                height="130"
                              />
                            </div>
                          </div>
                          <div className="flex flex-col justify-start items-start space-y-1 truncate">
                            <Link to={`/g/${gpt?.uniqueName}`}>
                              <span className="text-sm font-semibold">
                                {gpt.botName}
                              </span>
                            </Link>
                            <span className="max-w-md truncate text-xs text-token-text-tertiary">
                              {gpt.primaryDomain}
                            </span>
                            <div className="flex gap-2">
                              <div className="-mt-1">
                                <div className="flex flex-row items-center space-x-1">
                                  <div className="truncate text-xs text-token-text-tertiary">
                                    Note: {gpt.specialInstruction}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <Divider />
                        </Link>
                      ))}
                    </>
                  ) : (
                    "No results found"
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <Box
          sx={{
            columnGap: 2,
            display: "grid",
            marginBottom: "20px",
            rowGap: { xs: 3, md: 2 },
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(3, 1fr)",
            },
          }}
        >
          {selectedGpt ? (
            <>
              {selectedGpt?.map((gpt: any, index: any) => (
                <>
                  <Card
                    key={index}
                    className="relative flex flex-col justify-between items-start"
                    sx={{
                      border: "1px solid rgb(46 38 61 / 0.12)",
                      boxShadow: "none",
                      "&:hover": {
                        boxShadow:
                          "rgba(145, 158, 171, 0.24) -24px 24px 72px -8px",
                      },
                    }}
                  >
                    <div>
                      <Stack sx={{ p: 1 }}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={2.5}
                        >
                          <div className="flex items-start justify-start space-x-2 px-1 pt-3">
                            <figure className=" card-side ">
                              <img
                                alt={gpt.uniqueName}
                                className="w-16 h-16 flex bg-white items-center justify-center rounded-full border-2 border-dashed border-token-border-medium"
                                src={gpt.iconUrl}
                              />
                            </figure>
                            <div className="flex  flex-col">
                              <div className="self-end"></div>
                              <Link
                                to={`/g/${gpt.uniqueName}`}
                                color="inherit"
                                className=" self-start overflow-hidden whitespace-normal text-base font-semibold line-clamp-1 hover:link"
                              >
                                {gpt.botName}
                              </Link>
                              <div className="max-w-2 text-overflow-ellipsis overflow-hidden whitespace-nowrap text-xs">
                                @{gpt.user.username}
                              </div>
                              {gpt.LGBTQ && (
                                <span className="absolute top-3 right-3">
                                  <Chip
                                    className="self-center"
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    label={gpt.LGBTQ}
                                  />
                                </span>
                              )}
                            </div>
                          </div>
                        </Stack>
                        <Divider />
                        <Stack
                          className="mt-1"
                          direction="row"
                          alignItems="center"
                          spacing={2.5}
                        >
                          <div className=" px-3 ">
                            {gpt.primaryDomain && (
                              <div className="overflow-hidden text-xs line-clamp-4 ">
                                {gpt.primaryDomain && gpt.primaryDomain}
                              </div>
                            )}
                            {/* {gpt.experience && (
                            <div className="overflow-hidden text-xs line-clamp-4 mt-1">
                              <span className=" inline-flex items-center gap-x-1.5 py-0.5 px-1.5 rounded-md text-xs border border-1 border-[#000080]   ">
                                {gpt.experience + " year experience"}
                              </span>
                            </div>
                          )} */}
                            {/* {gpt.expertise && (
                            <div className="overflow-hidden text-xs line-clamp-4 ">
                              Expertise:
                              <hr />
                              <br />
                              <ul className="columns-2 gap-x-0.2 list-disc">
                                {gpt.expertise
                                  .split("#")
                                  .map((itm: any, idx: any) => (
                                    <li key={idx}>{itm}</li>
                                  ))}
                              </ul>
                            </div>
                          )} */}
                          </div>
                        </Stack>
                      </Stack>
                      {gpt.theraupatic_approaches && (
                        <>
                          <Grid
                            container
                            spacing={1.5}
                            sx={{
                              p: 3,
                              mt: 1,
                              pt: 0,
                              typography: "body2",
                              color: "text.secondary",
                              textTransform: "capitalize",
                            }}
                          >
                            <Grid>
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ typography: "body2" }}
                                className="flex flex-wrap gap-1"
                              >
                                {gpt.theraupatic_approaches
                                  .split("#")
                                  .map((itm: any, idx: any) => (
                                    <Chip
                                      key={idx}
                                      variant="outlined"
                                      label={itm}
                                      size="small"
                                    />
                                  ))}
                              </Stack>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </div>
                    <div className="!w-full">
                      <Divider sx={{ borderStyle: "dashed" }} />
                      <div className=" flex  flex-row items-center justify-center text-center px-1 my-1">
                        <div className=" max-w-2 overflow-hidden line-clamp-2 whitespace-break-spaces text-xs text-center my-1 hover:link">
                          {gpt.specialInstruction}
                        </div>
                      </div>
                    </div>
                  </Card>
                  {/* <div className="shadow-md">
                    <div className="flex items-start justify-start space-x-2 px-1 pt-3">
                      <figure className=" card-side ">
                        <img
                          alt={gpt.uniqueName}
                          className="w-16 h-16 flex bg-white items-center justify-center rounded-full border-2 border-dashed border-token-border-medium"
                          src={gpt.iconUrl}
                        />
                      </figure>
                      <div className="flex !w-min flex-col">
                        <div className="self-end"></div>
                        <Link
                          to={`/g/${gpt.uniqueName}`}
                          color="inherit"
                          className=" self-start overflow-hidden whitespace-normal text-base font-semibold line-clamp-1 hover:link"
                        >
                          {gpt.botName}
                        </Link>
                        <div className="max-w-2 text-overflow-ellipsis overflow-hidden whitespace-nowrap text-xs">
                          @{gpt.user.username}
                        </div>
                        {gpt.LGBTQ && (
                          <div className="overflow-hidden text-xs line-clamp-4 mt-1">
                            LGBTQ: {gpt.LGBTQ}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className=" px-3 ">
                      {gpt.primaryDomain && (
                        <div className="overflow-hidden text-xs line-clamp-4 ">
                          Expertise: {gpt.primaryDomain && gpt.primaryDomain}
                        </div>
                      )}

                      {gpt.expertise && (
                        <div className="overflow-hidden text-xs line-clamp-4 ">
                          Expertise: {gpt.expertise && gpt.expertise}
                        </div>
                      )}
                      {gpt.experience && (
                        <div className="overflow-hidden text-xs line-clamp-4 ">
                          Expertise: {gpt.experience} Years
                        </div>
                      )}
                      {theraupatic_approaches && (
                        <div className=" flex justify-start flex-wrap gap-1 text-xs my-2">
                          {gpt && gpt.theraupatic_approaches}
                        </div>
                      )}

                      <div className="">
                        <Divider />
                        <div className=" flex  flex-row items-center justify-center px-1 my-1">
                          <div className=" max-w-2 overflow-hidden line-clamp-2 whitespace-break-spaces text-xs text-center my-1 hover:link">
                            {gpt.specialInstruction}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </>
              ))}
            </>
          ) : (
            <>
              {[...Array(9)].map((_, index) => (
                <GptsStoreSkeleton />
              ))}
            </>
          )}
        </Box>
      </Box>
      {allGpts && (
        <Pagination
          count={Math.ceil(allGpts.length / 12)}
          color="primary"
          size="large"
          sx={{
            my: 10,
            "& .MuiPagination-ul": {
              justifyContent: "center",
            },
          }}
        />
      )}
      {/* Drawer for Multiple Search */}
      <Drawer
        placement="right"
        open={openAdSearch}
        onClose={() => {
          setOpenAdSearch(!openAdSearch);
        }}
      >
        <div className="flex items-center justify-between px-4 pb-2">
          <Typography variant="h5" color="blue-gray">
            Advance Search
          </Typography>
          <IconButton
            variant="text"
            color="blue-gray"
            onClick={() => setOpenAdSearch(!openAdSearch)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </IconButton>
        </div>

        <form className="flex flex-col gap-6 p-4">
          <Input
            label="Bot Name "
            crossOrigin={undefined}
            value={search.botName}
            onChange={(e) => handleSearchChange("botName", e.target.value)}
          />
          <Select
            size="md"
            label="LGBTQ"
            value={search.LGBTQ}
            onChange={(value) => handleSearchChange("LGBTQ", value)}
          >
            <Option value="Gay">Gay</Option>
            <Option value="Lesbian">Lesbian</Option>
            <Option value="Transgender">Transgender</Option>
            <Option value="Non-binary">Non-binary</Option>
            <Option value="Bisexual">Bisexual</Option>
            <Option value="Other">Other</Option>
          </Select>
          <Select
            size="md"
            label="Theraupatic Approaches"
            value={search.theraupatic_approaches}
            onChange={(value) =>
              handleSearchChange("theraupatic_approaches", value)
            }
          >
            {theraupatic_approaches.map((itm) => (
              <Option value={itm.value}>{itm.value}</Option>
            ))}
          </Select>
          <Textarea
            resize={true}
            rows={1}
            label="Coach Domain"
            value={search.primaryDomain}
            onChange={(e) =>
              handleSearchChange("primaryDomain", e.target.value)
            }
          />
          <Input
            type="number"
            label="Experience (Years)"
            crossOrigin={undefined}
            value={search.experience}
            onChange={(e) => handleSearchChange("experience", e.target.value)}
          />
          <Select
            size="md"
            label="Expertise"
            value={search.expertise}
            onChange={(value) => handleSearchChange("expertise", value)}
          >
            {allExpertise.map((itm) => (
              <Option value={itm.value}>{itm.value}</Option>
            ))}
          </Select>
          <Button onClick={handleSubmitSearch}>Search Now</Button>
        </form>
      </Drawer>
    </>
  );
};

export default GptsStore;
