import React from "react";
import { Grid, Typography, Box, Stack } from "@mui/material";

import useStyles from "./styles/styles";

import Shape from "./components/Shape";
import { FaQuoteLeft } from "react-icons/fa";

const Sayings = ({ title, name }: any) => {
  const classes = useStyles();

  return (
    <Box className={classes.sectionBox}>
      <Shape secNumber="4" />
      <Grid
        container
        justifyContent="center"
        sx={{
          textAlign: "center",
          overflow: "hidden",
          py: { xs: 10, md: 15 },
        }}
      >
        <Grid xs={12} md={6}>
          <Stack alignItems="center">
            <FaQuoteLeft className="w-[30px] h-[30px] text-[#000080]" />
            <Typography
              sx={{
                mt: 2,
                mb: 2,
                lineHeight: 1.75,
                fontSize: { xs: 20, md: 16 },
                // fontFamily: (theme) => theme.typography.h1.fontFamily,
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: "bold",
                // fontFamily: (theme) => theme.typography.h1.fontFamily,
              }}
            >
              {name}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Sayings;
