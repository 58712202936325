import { Routes as CommonRoutes, Navigate, Route } from "react-router-dom";

// ** Import pages
import Chat from "./pages/chat";
import Profile from "./pages/profile";
import Help from "./pages/help";
import Dedicate from "./pages/dedicate";
import { TrainBook, TrainWebContent } from "./pages/adminDashboard/train";

import Layout from "./layouts";
import AuthProvider from "./layouts/auth-provider";

// ** import type
import type { FC, ReactNode } from "react";
import VerifyPage from "./pages/verifyPage";
import NotVerifyPage from "./pages/notverifyPage";
import { Users } from "./pages/adminDashboard";
import { useSelector } from "react-redux";
import { OtherAdmin } from "./pages/Personality/Admin/other";
import PersonalityAdmin from "./pages/Personality/Admin/other/personalityAdmin";
import BotSuggestions from "./pages/Embedding/BotSuggestions";
import Integrations from "./pages/Embedding/Integrations";
import GptsStore from "./pages/GptStore/GptsStore";
import ChatGpts from "./pages/GptStore/ChatGpts";
import Home from "./pages";
import TermsOfServices from "./pages/TermsOfServices";
import PrivacyPolicy from "./pages/PrivacyPolicy";

interface MainLayoutProps {
  children?: ReactNode;
}

const Wrapper: FC<MainLayoutProps> = ({ children }) => {
  return (
    <AuthProvider>
      <Layout>{children}</Layout>
    </AuthProvider>
  );
};

const Routes = () => {
  const { user } = useSelector((state: any) => state.auth);

  return (
    <CommonRoutes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
      <Route path="/terms-of-service/" element={<TermsOfServices />}></Route>
      <Route
        path="/gpts"
        element={
          <Wrapper>
            <GptsStore />
          </Wrapper>
        }
      ></Route>
      <Route
        path="/g/:uniqueName/*"
        element={
          <Wrapper>
            <ChatGpts />
          </Wrapper>
        }
      ></Route>
      <Route
        path="/chat"
        element={
          <Wrapper>
            <Chat />
          </Wrapper>
        }
      ></Route>
      <Route
        path="/data"
        element={
          <Wrapper>
            <Dedicate />
          </Wrapper>
        }
      ></Route>
      <Route
        path="/documents"
        element={
          user?.attributes?.isAdmin?.[0] === "true" ? (
            <Wrapper>
              <TrainBook />
            </Wrapper>
          ) : (
            <Navigate to="/chat" />
          )
        }
      ></Route>
      <Route
        path="/personality"
        element={
          user?.attributes?.isAdmin?.[0] === "true" ? (
            <Wrapper>
              <PersonalityAdmin />
            </Wrapper>
          ) : (
            <Navigate to="/chat" />
          )
        }
      ></Route>
      <Route
        path="/system-settings"
        element={
          user?.attributes?.isAdmin?.[0] === "true" ? (
            <Wrapper>
              <OtherAdmin />
            </Wrapper>
          ) : (
            <Navigate to="/chat" />
          )
        }
      ></Route>
      <Route
        path="/integration"
        element={
          <Wrapper>
            <Integrations />
          </Wrapper>
        }
      ></Route>
      <Route
        path="/suggestions"
        element={
          <Wrapper>
            <BotSuggestions />
          </Wrapper>
        }
      ></Route>
      <Route
        path="/websites"
        element={
          user?.attributes?.isAdmin?.[0] === "true" ? (
            <Wrapper>
              <TrainWebContent />
            </Wrapper>
          ) : (
            <Navigate to="/chat" />
          )
        }
      ></Route>
      <Route
        path="/users"
        element={
          user?.attributes?.isAdmin?.[0] === "true" ? (
            <Wrapper>
              <Users />
            </Wrapper>
          ) : (
            <Navigate to="/chat" />
          )
        }
      ></Route>
      <Route
        path="/profile"
        element={
          <Wrapper>
            <Profile />
          </Wrapper>
        }
      ></Route>
      <Route
        path="/help"
        element={
          <Wrapper>
            <Help />
          </Wrapper>
        }
      ></Route>
      {/* <Route
        path="/upgrade"
        element={
          <Wrapper>
            <UpgradePlan />
          </Wrapper>
        }
      ></Route> */}
      <Route path="/verify/:id/:token" element={<VerifyPage />}></Route>
      <Route
        path="/notverify"
        element={
          <AuthProvider>
            <NotVerifyPage />
          </AuthProvider>
        }
      ></Route>
      <Route path="*" element={<Navigate to="/" />} />
    </CommonRoutes>
  );
};
export default Routes;
