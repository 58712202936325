import { useState } from "react";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
  Dialog,
  DialogBody,
  Typography,
} from "@material-tailwind/react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { deleteChatHistory } from "../../store/messages/actions";
import WarningIcon from "@mui/icons-material/Warning";

export function DeleteChatHistory() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const deleteHandler = () => {
    dispatch(deleteChatHistory());
    setOpen(!open);
  };
  const handleOpen = () => setOpen(!open);

  return (
    <>
      <Menu placement="top-end">
        <MenuHandler>
          <MoreVertIcon className="cursor-pointer" />
        </MenuHandler>
        <MenuList className="!w-2 p-0">
          <MenuItem
            onClick={handleOpen}
            className="flex justify-between items-center gap-2 "
          >
            <Typography variant="small" className="!text-sm text-red-500">
              Clear Chat
            </Typography>
            <DeleteIcon className="text-red-500 !text-base" />
          </MenuItem>
        </MenuList>
      </Menu>

      <Dialog size="xs" open={open} handler={handleOpen}>
        <DialogBody divider className="grid place-items-start gap-4">
          <Typography color="black" variant="lead">
            <WarningIcon className="text-red-500" /> Confirmation
          </Typography>
          <Typography className="text-center font-normal">
            Are you sure you want to delete your chat?
          </Typography>
          <div className="w-full flex justify-end items-end">
            <Button
              size="sm"
              variant="text"
              color="blue-gray"
              className=" normal-case font-medium"
              onClick={handleOpen}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              variant="text"
              onClick={deleteHandler}
              className="text-red-500 normal-case font-medium"
            >
              Delete
            </Button>
          </div>
        </DialogBody>
      </Dialog>
    </>
  );
}
