import React from "react";
import {
  Grid,
  Typography,
  Box,
  Container,
  // Link
} from "@mui/material";
// import { useNavigate } from 'react-router-dom';

import useStyles from "./styles/styles";

import Shape from "./components/Shape";
const HowItWorks = () => {
  // const navigate = useNavigate();
  const classes = useStyles();

  const clases = {
    header2: {
      fontSize: { xs: "7.63vw", md: "3.18vw !important" },
      fontWeight: "600 !important",
      color: "#101010 !important",
      mb: { md: "3vw", xs: "0" },
    },
    header3: {
      fontSize: { xs: "7.63vw", md: "2.6vw !important" },
      fontWeight: "600 !important",
      color: "#101010 !important",
    },
    contentText: {
      fontSize: { xs: "4.07vw !important", md: "1.15vw !important" },
      fontWeight: "500 !important",
      color: "#757B8A !important",
      my: { xs: "5vw", md: "0" },
      // textAlign: { xs: 'center', md: 'left' },
    },
    buttonSx: {
      width: { md: "22.48vw", xs: "39.95vw" },
      fontSize: { xs: "3.56vw", md: "1.11vw" },
      paddingY: "2vw",
    },
    readmoreText: {
      fontSize: { xs: "4.07vw !important", md: "1.2vw !important" },
      fontWeight: "500 !important",
      color: "#000080 !important",
      textAlign: "left",
    },
  };
  return (
    <Grid
      container
      className={classes.sectionBox}
      sx={{ background: "#F7F9FF" }}
    >
      <Shape secNumber="3" />
      <Grid
        container
        className={classes.containerGrid}
        sx={{ py: { xs: "16.03vw", md: "3vw" } }}
      >
        <Grid item className={classes.flexCenter} xs={11} md={12}>
          <Typography id="how" sx={clases.header2}>
            How Does it Work?
          </Typography>
        </Grid>
        <Container>
          <Grid
            item
            className={classes.flexCenter}
            sx={{
              display: "flex",
              flexDirection: { xs: "column-reverse", md: "row" },
            }}
          >
            <Grid item>
              <Box>
                <Typography sx={clases.contentText} textAlign={"center"}>
                  When you ask a question, EntheoGPT searches and reads hundreds
                  of relevant community contributed scientific research
                  documents to generate the best response using LLMs or Large
                  Language Models like ChatGPT. It's trained specifically on a
                  large set of Entheogenic research but unlike public LLMs we
                  list the reference docs used to generate the response. This
                  dramatically reduces research time because AI technology
                  understands context where traditional search platforms don't.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default HowItWorks;
