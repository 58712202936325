import { useRef, useEffect, useState, useCallback } from "react";
import ContentEditable from "react-contenteditable";
import { useDispatch, useSelector } from "react-redux";

// ** Import MUI
import { IconButton, Box, useTheme } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import StopCircleIcon from "@mui/icons-material/StopCircle";

// ** Import Type
import { FC } from "react";

// ** Import action
import {
  addFavGpts,
  getBotAnswer,
  updateBotChatStreaming,
} from "../../store/bot/actions";
import { useSearchParams } from "react-router-dom";
interface ContentEditProps {
  setShowSuggestions: (showSuggestions: boolean) => void;
  handleStopStreaming: () => void;
}

const ContentEdit: FC<ContentEditProps> = ({
  setShowSuggestions,
  handleStopStreaming,
}) => {
  const dispatch = useDispatch();
  const { botDetails, isBotStreaming } = useSelector((state: any) => state.bot);
  const [searchParams, setSearchParams] = useSearchParams();

  const theme = useTheme();
  const [text, setText] = useState("");
  const inputBoxRef = useRef(null);

  const handleChange = (evt: any) => {
    const value = evt.target.value.replace(/<[^>]+>/g, "");
    setText(value);
  };

  useEffect(() => {
    const query = searchParams.get("q");
    if (query) {
      setText(decodeURI(query));
      // onSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const onSubmit = useCallback(() => {
    const query = decodeURI(searchParams.get("q") || "");
    if (text !== "" || query !== "") {
      dispatch(addFavGpts(botDetails?.id));
      dispatch(
        getBotAnswer({
          botId: botDetails?.id,
          question: text ? text : query,
          llm: JSON.parse(
            localStorage.getItem("model") || "{}"
          )?.model?.toLocaleLowerCase(),
        })
      );
      dispatch(updateBotChatStreaming(true));
      setShowSuggestions(false);
      setText("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, dispatch]);

  useEffect(() => {
    const handleKeyDown = (e: any) => {
      if (e.key === "Enter") {
        e.preventDefault();
        if (e.ctrlKey || e.shiftKey) {
          document.execCommand("insertLineBreak");
        } else {
          onSubmit();
        }
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onSubmit]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        <ContentEditable
          disabled={isBotStreaming}
          className={`content-editable !p-[12px_24px] !rounded-md ${
            isBotStreaming && "cursor-wait"
          } ${theme?.palette.mode === "dark" && "!bg-[#1a2035]"}`}
          data-text="Send a message"
          innerRef={inputBoxRef}
          html={text}
          onChange={handleChange}
        />
        {isBotStreaming ? (
          <IconButton
            sx={{ position: "absolute", right: "5px" }}
            aria-label="Submit"
            onClick={handleStopStreaming}
          >
            <StopCircleIcon color="primary" />
          </IconButton>
        ) : (
          <IconButton
            sx={{ position: "absolute", right: "5px" }}
            aria-label="Submit"
            onClick={onSubmit}
          >
            <SendIcon color="primary" />
          </IconButton>
        )}
      </Box>
    </>
  );
};

export default ContentEdit;
