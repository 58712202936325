import { setSessionExpired, useMainController } from "../../../../context";
import http from "../../../../services/http-common";
import { Button, Textarea } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

export function SearchPromptEditor() {
  const [ dispatch] = useMainController();
  const [prompt, setPrompt] = useState("");

  const onChangePrompt = (e) => {
    setPrompt(e.target.value);
  };

  const getPrompt = useMutation(
    () => {
      return http.get("chat/searchprompt", null, setSessionExpired, dispatch);
    },
    {
      onSuccess: ({ data }) => {
        setPrompt(data);
      },
      onError: (error) => {
        switch (error.response.status) {
          case 500: {
            toast.error("One problem is on server. Please try again later.");
            break;
          }
          case 404: {
            toast.error("Please check network status. And then try again.");
            break;
          }
          default:
            toast.error(error.message);
        }
      },
    }
  );

  useEffect(() => {
    getPrompt.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },      []);

  const savePrompt = useMutation(
    async () => {
      return await http.put("chat/searchprompt", { prompt: prompt });
    },
    {
      onSuccess: () => {
        toast.success("Successfully Saved!");
      },
      onError: (error) => {
        toast.error(error);
      },
    }
  );

  const onSavePrompt = () => {
    savePrompt.mutate();
  };
  return (
    <div className="flex w-full justify-center">
      <div className="mt-5 flex w-full max-w-[800px] flex-col gap-4">
        <Textarea
          value={prompt}
          onChange={onChangePrompt}
          label="Prompt for Search"
          resize
          className="min-h-[300px]"
        />
        <div className="flex justify-center">
          <Button onClick={onSavePrompt} className="min-w-[150px]">
            save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SearchPromptEditor;
