import {
  Box,
  Card,
  Chip,
  Divider,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllFavGpts,
  getAllGpts,
  showGPTDetails,
} from "../../store/bot/actions";
import GptsStoreSkeleton from "./GptsStoreSkeleton";
import ShowGptDetails from "./ShowGptDetails";

const GptsStore = () => {
  const { allGpts, favGpts } = useSelector((state: any) => state.bot);
  const [selectedGpt, setSelectedGpt] = useState(null);
  const [searchGpt, setSearchGpt] = useState("");
  const [allSearchGpt, setAllSearchGpt] = useState([]);
  const { isShowingGPT } = useSelector((state: any) => state.bot);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleSearch = (event: any) => {
    if (event.target.value) {
      const results = allGpts.filter((item: any) =>
        item.botName.toLowerCase().includes(event.target.value.toLowerCase())
      );
      setAllSearchGpt(results);
    } else {
      setAllSearchGpt([]);
    }
    setSearchGpt(event.target.value);
  };

  const toogleOpen = () => {
    if (open === true) {
      setSelectedGpt(null);
    }
    isShowingGPT
      ? dispatch(showGPTDetails(false))
      : dispatch(showGPTDetails(true));
    setOpen(!open);
  };

  const handleEdit = (uniqueName: any) => {
    setSelectedGpt(uniqueName);
    toogleOpen();
  };
  useEffect(() => {
    !allGpts.length && dispatch(getAllGpts());
    !favGpts.length && dispatch(getAllFavGpts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Box
        id="scrollableDiv"
        className="max-h-[calc(100vh-200px)] md:max-h-[calc(100vh-190px)] lg:max-h-[calc(100vh-190px)] xl:max-h-[calc(100vh-190px)] "
        sx={{
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <div className="mx-auto w-full max-w-4xl text-center my-4">
          <h5 className="text-xl font-semibold md:text-2xl">
            Discover the Best GPTs
          </h5>
          <p className="w-full mt-2 text-zinc-600">
            {" "}
            Feel free to use. Find All the GPT You Need on EntheoGPT.
          </p>
          <div
            className="group relative rounded-xl z-20 mb-6 mt-2 flex-grow shadow-[0px_10px_10px_-6px_rgba(0,0,0,0.04)]"
            data-headlessui-state=""
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
              className="pointer-none icon-md absolute top-0 h-full text-token-text-tertiary left-5"
            >
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M10.75 4.25a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13m-8.5 6.5a8.5 8.5 0 1 1 15.176 5.262l4.031 4.03a1 1 0 0 1-1.414 1.415l-4.031-4.031A8.5 8.5 0 0 1 2.25 10.75"
                clip-rule="evenodd"
              ></path>
            </svg>
            <input
              className="z-10 w-full rounded-xl border border-token-border-light bg-token-main-surface-primary py-2 pr-3 font-normal outline-0 delay-100 md:h-14 h-12 text-base pl-12"
              placeholder="Search GPTs"
              value={searchGpt}
              onChange={(e) => {
                handleSearch(e);
              }}
            />
            {searchGpt && (
              <div
                className="absolute !bg-white  top-[calc(100%-10px)] w-full overflow-y-auto rounded-lg rounded-t-none border border-t-0 border-token-border-light bg-token-main-surface-primary px-3 py-2 opacity-100 translate-y-0"
                id="headlessui-popover-panel-:r2n:"
                tabIndex={-1}
                data-headlessui-state=""
              >
                {/* <div className="px-3 pb-2 text-xs text-token-text-tertiary font-semibold ">
                Recently Used
              </div> */}

                {allSearchGpt.length !== 0 ? (
                  <>
                    {allSearchGpt.map((gpt: any) => (
                      <Link
                        to={`#`}
                        onClick={() => {
                          handleEdit(gpt.uniqueName);
                        }}
                        key={gpt.id}
                        className=" cursor-pointer group mx-2 flex items-center gap-3 rounded-lg px-3 py-2 hover:bg-gray-50 dark:hover:bg-white/10"
                      >
                        <div className="h-10 w-10 shrink-0">
                          <div className=" overflow-hidden rounded-full">
                            <img
                              src={gpt.iconUrl}
                              className="h-full w-full  flex bg-white items-center justify-center rounded-full border-2 border-dashed border-token-border-medium"
                              alt={gpt.botName}
                              width="130"
                              height="130"
                            />
                          </div>
                        </div>
                        <div className="flex flex-col space-y-1 truncate">
                          <div className="flex flex-row items-end space-x-2">
                            <span className="shrink-0 truncate text-sm font-semibold">
                              {gpt.botName}
                            </span>
                          </div>
                          <span className="max-w-md truncate text-xs text-token-text-tertiary">
                            {gpt.primaryDomain}
                          </span>
                          <div className="flex gap-2">
                            <div className="-mt-1">
                              <div className="flex flex-row items-center space-x-1">
                                <div className="truncate text-xs text-token-text-tertiary">
                                  Note: {gpt.specialInstruction}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </>
                ) : (
                  "No results found"
                )}
              </div>
            )}
          </div>
        </div>

        <Box
          sx={{
            columnGap: 2,
            display: "grid",
            rowGap: { xs: 3, md: 2 },
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(3, 1fr)",
            },
          }}
        >
          {allGpts?.length ? (
            allGpts.map((gpt: any, index: any) => (
              <>
                <Card
                  key={index}
                  className="relative"
                  sx={{
                    border: "1px solid rgb(46 38 61 / 0.12)",
                    boxShadow: "none",
                    "&:hover": {
                      boxShadow:
                        "rgba(145, 158, 171, 0.24) -24px 24px 72px -8px",
                    },
                  }}
                >
                  <Stack sx={{ p: 1 }}>
                    <Stack direction="row" alignItems="center" spacing={2.5}>
                      <img
                        alt={gpt.botName}
                        src={
                          gpt.iconUrl
                            ? gpt.iconUrl
                            : "https://files.oaiusercontent.com/file-4u5wkqX2w4i8SBpkAgthvVXC?se=2124-04-07T20%3A55%3A23Z&amp;sp=r&amp;sv=2021-08-06&amp;sr=b&amp;rscc=max-age%3D1209600%2C%20immutable&amp;rscd=attachment%3B%20filename%3DGPT%2520Store%2520%25282%2529.png&amp;sig=v4ih98prPTbs%2BbJFWJjI2uIadNPos1me4fAuUtiWRTo%3D"
                        }
                        style={{ width: 48, height: 48, borderRadius: 1 }}
                      />

                      <Stack spacing={0.5}>
                        <Link
                          // to={`/entheogpt/${gpt.id}`}
                          to={`#`}
                          color="inherit"
                          onClick={() => {
                            handleEdit(gpt.uniqueName);
                          }}
                        >
                          <h6>{gpt.botName}</h6>
                        </Link>

                        <Typography variant="body2" sx={{ color: "info.main" }}>
                          {gpt.tone}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Divider sx={{ borderStyle: "dashed", my: 2 }} />

                  <Grid
                    container
                    spacing={1.5}
                    sx={{
                      p: 3,
                      pt: 0,
                      typography: "body2",
                      color: "text.secondary",
                      textTransform: "capitalize",
                    }}
                  >
                    <Grid>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ typography: "body2" }}
                        className="flex flex-wrap gap-1"
                      >
                        {gpt.primaryDomain
                          .split(",")
                          .map((itm: any, idx: any) => (
                            <Chip
                              key={idx}
                              variant="outlined"
                              label={itm}
                              size="small"
                            />
                          ))}
                      </Stack>
                    </Grid>
                  </Grid>
                </Card>
              </>
            ))
          ) : (
            <>
              {[...Array(9)].map((_, index) => (
                <GptsStoreSkeleton />
              ))}
            </>
          )}
        </Box>
      </Box>
      {allGpts && (
        <Pagination
          count={Math.ceil(allGpts.length / 12)}
          color="primary"
          size="large"
          sx={{
            my: 10,
            "& .MuiPagination-ul": {
              justifyContent: "center",
            },
          }}
        />
      )}
      <ShowGptDetails toogleOpen={toogleOpen} gptUniqueName={selectedGpt} />
    </>
  );
};

export default GptsStore;
