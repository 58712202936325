import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";

// import footer from "./home/footer";
// import section1 from "./home/section1";

import Header from "./home/Header";
import Footer from "./home/Footer";
import HomeHero from "./home/HomeHero";
import Introduction from "./home/Introduction";
import Testimonials from "./home/Testimonials";
import HowItWorks from "./home/HowItWorks";
import AIIntro from "./home/AIIntro";
import RagIntro from "./home/RagIntro";
import ExploreGpts from "./home/ExploreGpts";
import JoinWaitlist from "./home/JoinWaitlist";
import SaifArchitecture from "./home/SaifArchitecture";
import Sayings from "./home/Sayings";
import MissionVision from "./home/MissionVision";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserService from "../KeyCloackServices/UserService";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000080",
    },
    secondary: {
      main: "#ffffff",
    },
  },
  typography: {
    fontFamily: ["Poppins", "Inter", "sans-serif"].join(","),
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: ".7vw",
          width: "10vw",
          fontSize: "1vw",
          fontWeight: "700",
          textAlign: "center",
          color: "#FFFFFF",
          textTransform: "none",
          borderRadius: "50vw",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: "50vw",
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        paragraph: true,
        mb: "1.5vw",
      },
    },
  },
});

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (UserService.isLoggedIn()) {
      navigate("/chat");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header />
        <>
          <HomeHero />
          <Introduction />
          <Testimonials
            testimonials={[
              {
                id: "1",
                name: "R.H",
                rating: 5,
                review:
                  "In a few days I was able to discover more about myself using EntheoGPT than a lifetime of searching on my own! Who knew that schizophrenia in my family history could cause psilocybin induced psychopathy? Thank you EntheoGPT!",
              },
            ]}
          />
          <HowItWorks />
          <Sayings
            title={
              "Ask, and it shall be given you; seek, and ye shall find; knock, and it shall be opened unto you."
            }
            name="Matthew 7:7 (KJV)"
          />
          <AIIntro />
          <RagIntro />
          <ExploreGpts />
          <JoinWaitlist
            heading="Join Now For First Access!"
            text={
              <>
                <u>Sign up today</u> for early access and be ready to transform
                the way you explore and understand entheogens with the support
                of the latest in AI technology!
              </>
            }
          />
          <SaifArchitecture />
          <Sayings
            title={"Question authority. Think for yourself."}
            name="Terrence McKenna"
          />
          <MissionVision />
          <JoinWaitlist
            heading="Join Now!"
            text={
              <>
                Try our other apps like{" "}
                <a
                  href="https://psiloscreen.me/"
                  target="_blank"
                  rel="noreferrer"
                  className="underline text-[#000080] font-bold"
                >
                  PsiloScreen
                </a>{" "}
                Psilocybin Pre-screening and{" "}
                <a
                  href="https://entheo.info/product/entheodna-home-kit/"
                  target="_blank"
                  rel="noreferrer"
                  className="underline text-[#000080] font-bold"
                >
                  EntheoDNA
                </a>{" "}
                Entheogenic Home Senstivity Test Kit
              </>
            }
          />
        </>
        <Footer />
      </ThemeProvider>
    </>
  );
};

export default Home;
