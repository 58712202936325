import { useState } from "react";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
  Dialog,
  DialogBody,
  Typography,
  DialogHeader,
  DialogFooter,
} from "@material-tailwind/react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import { cleanMessages, deleteChatHistory } from "../../store/messages/actions";
import WarningIcon from "@mui/icons-material/Warning";
import { Divider } from "@mui/material";
import { getSettingData } from "../sidebar";
import ProfileSetting from "../SettingsPages/profileSetting";
import CloseIcon from "@mui/icons-material/Close";
import { logout } from "../../store/auth/actions";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import http from "../../services/http-common";
import { MdAccountCircle } from "react-icons/md";
import NumberOfToken from "./NumberOfToken";
import TokenSummary from "../SettingsPages/Tokens/TokenSummary";
import { ShoppingCartIcon } from "@heroicons/react/24/outline";
import { TrashIcon } from "@heroicons/react/20/solid";
import { plansDetails } from "../../utils/PlanDetails";
import PricingTable from "../../pages/home/Pricing/PricingTable";
import { RiMessage3Line } from "react-icons/ri";

const MenuOptions = [
  {
    title: "Profile",
    option: <ProfileSetting />,
  },
  {
    title: "Tokens Summary",
    option: <TokenSummary />,
  },
  {
    title: "Create GPT",
    option: <PricingTable plans={plansDetails} />,
  },
];

export function SettingsMenu() {
  const dispatch = useDispatch();
  const [selectedTitle, setSelectedTitle] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const sections = getSettingData();
  const location = useLocation();

  const navigateURL = (url) => (window.location.href = url);

  const deleteHandler = () => {
    dispatch(deleteChatHistory());
    setOpen(!open);
  };
  const handleOpen = () => {
    setOpen(!open);
    setIsProfile(false);
  };
  const handleMenu = (text) => {
    setIsProfile(true);
    setSelectedTitle(text);
    setOpen(!open);
  };

  const onClickHandler = (path) => {
    if (typeof path === "string") {
      return () => {
        navigate(path);
      };
    } else {
      return () => {
        // alert("here");
        dispatch(cleanMessages());
        dispatch(logout());
      };
    }
  };
  const handleLogout = () => {
    dispatch(cleanMessages());
    dispatch(logout());
  };
  const checkMySubscrptions = () => {
    getOrdersList.mutate();
  };

  const getOrdersList = useMutation(
    () => {
      return http.post(`stripe/createCustomerPortalSession`, {}, null);
    },
    {
      onSuccess: ({ data }) => {
        navigateURL(data.url);
      },
      onError: (error) => {
        console.log(error);
      },
      onMutate: () => {
        // setLoading(true);
      },
      onSettled: () => {
        // setLoading(false);
      },
    }
  );
  return (
    <>
      <Menu placement="top-end">
        <MenuHandler>
          <MoreVertIcon className="cursor-pointer" />
        </MenuHandler>

        <MenuList className="!w-auto p-0 overflow-hidden">
          <MenuItem
            className="flex justify-start items-center gap-3 "
            onClick={() => handleMenu("Tokens Summary")}
          >
            <div>
              {user?.attributes?.avatar ? (
                <img
                  src={
                    user?.attributes?.avatar ? user?.attributes?.avatar[0] : ""
                  }
                  alt={user.firstName}
                  className="w-8 h-8 rounded-full"
                />
              ) : (
                <MdAccountCircle className="w-8 h-8 !text-xl" />
              )}
            </div>
            <div class="user-info">
              <h6 class="font-bold">{user.firstName}</h6>
              <NumberOfToken />
            </div>
          </MenuItem>
          <Divider />
          {location.pathname === "/chat" && (
            <MenuItem
              onClick={handleOpen}
              className="flex justify-start items-center gap-3 "
            >
              <TrashIcon className="text-red-500 !text-base w-[22px]" />
              <Typography variant="small" className="!text-sm text-red-500">
                Clear Chat
              </Typography>
            </MenuItem>
          )}
          {/* {location.pathname.includes("/g/") && (
            <MenuItem className="flex justify-start items-center gap-3 ">
              <AlertDeleteBotChat />
            </MenuItem>
          )} */}

          <Divider />

          <MenuItem
            onClick={() => handleMenu("Create GPT")}
            className="flex justify-start items-center gap-3 "
          >
            <RiMessage3Line className="w-5 h-5 !text-xl" />
            {/* {_item.icon} */}
            <Typography variant="small" className="!text-sm ">
              Create GPT
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              checkMySubscrptions();
            }}
            className="flex justify-start items-center gap-3 "
          >
            <ShoppingCartIcon className="w-[21px]" />
            {/* {_item.icon} */}
            <Typography variant="small" className="!text-sm ">
              Subscription
            </Typography>
          </MenuItem>
          <Divider />

          {sections.map((_item) => {
            return (
              <>
                {_item?.item?.map((_item, idx) => (
                  <>
                    {_item.title === "Profile" ? (
                      <MenuItem
                        key={idx}
                        onClick={() => {
                          handleMenu(_item.title);
                        }}
                        className="flex justify-start items-center gap-3 "
                      >
                        {/* <DeleteIcon className="text-red-500 !text-base" /> */}
                        {_item.icon}
                        <Typography variant="small" className="!text-sm ">
                          {_item.title}
                        </Typography>
                      </MenuItem>
                    ) : (
                      <>
                        {_item.title !== "Email" && (
                          <>
                            {_item.title === "Logout" && <Divider />}
                            <MenuItem
                              key={idx}
                              onClick={() => {
                                _item?.title === "Logout"
                                  ? handleLogout()
                                  : onClickHandler(_item.path);
                              }}
                              className="flex justify-start items-center gap-3 "
                            >
                              {/* <DeleteIcon className="text-red-500 !text-base" /> */}
                              {_item.icon}
                              <a
                                rel="noreferrer"
                                href={_item?.link ? _item?.link : "#"}
                                target={_item?.link ? "_blank" : "_self"}
                              >
                                <Typography
                                  variant="small"
                                  className="!text-sm "
                                >
                                  {_item.title}
                                </Typography>
                              </a>
                              {_item?.setting && _item?.setting}
                            </MenuItem>
                          </>
                        )}
                      </>
                    )}
                  </>
                ))}
              </>
            );
          })}
        </MenuList>
      </Menu>

      <Dialog
        size={
          isProfile && selectedTitle !== "Create GPT"
            ? "md"
            : selectedTitle === "Create GPT"
            ? "xl"
            : "sm"
        }
        open={open}
        handler={handleOpen}
      >
        {selectedTitle !== "Create GPT" && (
          <>
            {isProfile ? (
              <DialogHeader className="flex justify-between items-center text-xl">
                {MenuOptions.map(
                  (item) =>
                    item.title === selectedTitle && (
                      <div key={item.title}>{item.title}</div>
                    )
                )}
                <CloseIcon onClick={handleOpen} className="cursor-pointer" />
              </DialogHeader>
            ) : (
              <DialogHeader>
                <Typography variant="h5" color="blue-gray">
                  Your Attention is Required!
                </Typography>
              </DialogHeader>
            )}
          </>
        )}

        <DialogBody
          divider
          className={`relative ${
            isProfile
              ? "grid place-items-start  max-h-[40rem] overflow-x-hidden"
              : "grid place-items-center gap-4"
          } ${selectedTitle === "LeaderBoard" ? "!p-0" : "overflow-scroll"}`}
        >
          {isProfile ? (
            <>
              {MenuOptions.map(
                (item) => item.title === selectedTitle && item.option
              )}

              {selectedTitle === "LeaderBoard" && (
                <span>
                  <CloseIcon
                    onClick={handleOpen}
                    className="cursor-pointer absolute top-3 right-3 text-white"
                  />
                </span>
              )}
            </>
          ) : (
            <>
              <Typography color="black" variant="lead">
                <WarningIcon className="text-red-500" /> Confirmation
              </Typography>
              <Typography className="text-center font-normal">
                Are you sure you want to delete your chat?
              </Typography>
              <DialogFooter className="space-x-2">
                <Button
                  size="sm"
                  variant="outlined"
                  color="blue-gray"
                  className="normal-case font-medium"
                  onClick={handleOpen}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  variant="gradient"
                  onClick={deleteHandler}
                  className=" normal-case font-medium"
                >
                  Delete
                </Button>
              </DialogFooter>
            </>
          )}
        </DialogBody>
      </Dialog>
    </>
  );
}
