import { Box } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllFavGpts } from "../../store/bot/actions";
import SidebarSection from "./sidebar-section";

const FavGptsList = () => {
  const { favGpts } = useSelector((state: any) => state.bot);
  const dispatch = useDispatch();

  useEffect(() => {
    !favGpts.length && dispatch(getAllFavGpts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Box>
        {favGpts &&
          favGpts?.map((_item: any) => {
            return (
              <SidebarSection key={_item.key} item={_item}></SidebarSection>
            );
          })}
      </Box>
    </>
  );
};

export default FavGptsList;
