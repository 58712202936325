import { useEffect, useState } from "react";
import { Box, Divider, Paper } from "@mui/material";
import SidebarSection from "./sidebar-section";
// import Upgrade from "./upgrade";

// ** Import Icon
// import LiveHelpRoundedIcon from '@mui/icons-material/LiveHelpRounded';
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";

// ** Import type
import { FC, ReactNode } from "react";
import { SwitchModeButton } from "../SwitchModeButton";
import { HiEnvelope, HiSun } from "react-icons/hi2";
import { MdAccountCircle } from "react-icons/md";
import { HiMiniQuestionMarkCircle } from "react-icons/hi2";

import EPImg from "../../assets/images/egpt-logo-social.png";

import {
  DocumentIcon,
  UsersIcon,
  DocumentTextIcon,
  BoltIcon,
  GlobeAltIcon,
  SquaresPlusIcon,
  Square3Stack3DIcon,
  Squares2X2Icon,
} from "@heroicons/react/20/solid";

import ProfileMenu from "../Header/ProfileMenu";
import ProfileSetting from "../SettingsPages/profileSetting";
import UserService from "../../KeyCloackServices/UserService";
import FavGptsList from "../../pages/GptStore/FavGptsList";

const icon = {
  width: "30px",
};
interface Item {
  title: string;
  path?: string;
  permission?: string;
  icon: ReactNode;
  option?: ReactNode;
  setting?: ReactNode;
  link?: string;
}

interface Section {
  key: number;
  title?: string;
  item: Item[];
}

export const getSectionsAdmin = (): Section[] => [
  {
    key: 1,
    item: [
      {
        title: "Magic Myc",
        path: "/chat",
        icon: (
          <img
            src="/images/botIco.png"
            alt="bot logo"
            className="max-w-[40px] max-h-[40px]"
          />
        ),
      },
      // {
      // 	title: 'Training Text',
      // 	path: '/training_text',
      // 	// permission: 'pro',
      // 	icon: <UploadFileRoundedIcon />,
      // },
      {
        title: "Documents",
        path: "/documents",
        icon: <DocumentIcon {...icon} />,
      },
      {
        title: "Websites",
        path: "/websites",
        icon: <GlobeAltIcon {...icon} />,
      },
      {
        title: "User",
        path: "/users",
        icon: <UsersIcon {...icon} />,
      },
      // {
      // 	title: 'Help',
      // 	path: '/help',
      // 	icon: <LiveHelpRoundedIcon />,
      // },
    ],
  },
  {
    key: 2,
    title: "Settings",
    item: [
      {
        title: "System Settings",
        path: "/system-settings",
        icon: <SquaresPlusIcon {...icon} />,
      },
      {
        title: "Personality",
        path: "/personality",
        icon: <BoltIcon {...icon} />,
      },
      {
        title: "Suggestions",
        path: "/suggestions",
        icon: <Square3Stack3DIcon {...icon} />,
      },
      {
        title: "Submit Research",
        path: "/data",
        icon: <DocumentTextIcon {...icon} />,
      },
    ],
  },
];
export const getSettingData = (): Section[] => [
  {
    key: 1,
    title: "Account",
    item: [
      {
        title: "Email",
        path: "#",
        icon: <HiEnvelope className="!text-xl" />,
      },
      {
        title: "Profile",
        path: "#",
        icon: <MdAccountCircle className="!text-xl" />,
        option: <ProfileSetting />,
      },
      // {
      //   title: "Subscription",
      //   path: "#",
      //   icon: <HiSparkles className="!text-xl" />,
      //   option: null,
      //   setting: (
      //     <Typography
      //       color="blue-gray"
      //       className="font-light !text-xs text-[inherit]"
      //     >
      //       Free Plan
      //     </Typography>
      //   ),
      // },
      {
        title: "Color Scheme",
        path: "#",
        icon: <HiSun className="!text-xl" />,
        setting: <SwitchModeButton />,
      },
      // {
      //   title: "Upgrade",
      //   path: "#",
      //   icon: <HiArrowUpCircle className="!text-xl" />,
      //   option: null,
      // },
    ],
  },

  {
    key: 3,
    title: "About",
    item: [
      {
        title: "Help, Privacy & Terms",
        path: "#",
        icon: <HiMiniQuestionMarkCircle className="!text-xl" />,
        link: "https://entheo.info",
      },
    ],
  },
  {
    key: 4,
    item: [
      {
        title: "Logout",
        path: undefined,
        icon: <LogoutRoundedIcon />,
      },
    ],
  },
];
export const getSectionsSeeker = (): Section[] => [
  {
    key: 1,
    title: "Account",
    item: [
      {
        title: "Email",
        path: "#",
        icon: <HiEnvelope className="!text-xl" />,
        option: null,
      },
      {
        title: "Profile",
        path: "#",
        icon: <MdAccountCircle className="!text-xl" />,
        option: <ProfileSetting />,
      },
    ],
  },
  {
    key: 2,
    item: [
      {
        title: "Color Scheme",
        path: "#",
        icon: <HiSun className="!text-xl" />,
        setting: <SwitchModeButton />,
      },
    ],
  },
  {
    key: 3,
    title: "About",
    item: [
      {
        title: "Help, Privacy & Terms",
        path: "#",
        icon: <HiMiniQuestionMarkCircle className="!text-xl" />,
        option: null,
        link: "https://entheo.info",
      },
    ],
  },
  {
    key: 4,
    item: [
      {
        title: "Logout",
        icon: <LogoutRoundedIcon />,
        option: null,
      },
    ],
  },
];
export const getChatBots = (): Section[] => [
  {
    key: 1,
    item: [
      {
        title: "Submit Research",
        path: "/data",
        icon: <DocumentTextIcon {...icon} />,
      },
      {
        title: "Explore GPTs",
        path: "/gpts",
        icon: <Squares2X2Icon {...icon} />,
      },
      {
        title: "EntheoGPT",
        path: "/chat",
        icon: (
          <img
            src={EPImg}
            alt="bot logo"
            className="max-w-[40px] max-h-[40px]"
          />
        ),
      },
    ],
  },
];

interface SideBarProps {
  mode?: string;
}

const SideBar: FC<SideBarProps> = ({ mode }) => {
  // const { user } = useSelector((state: any) => state.auth);
  // const { favGpts } = useSelector((state: any) => state.bot);
  const sections = UserService.getRole()?.roles?.includes("admin")
    ? getSectionsAdmin()
    : getChatBots();

  const [buf, setBuf] = useState("");
  useEffect(() => {
    setBuf(mode === "drawer" ? "calc(100vh - 19rem )" : "calc(100vh - 260px)");
  }, [mode]);

  return (
    <>
      <Paper
        sx={{
          background: "background.default",
          border: "1px solid #B3B6DA",
          borderRadius: "18px",
          minHeight: "100%",
          position: "relative",
          minWidth: { xs: "80vw", md: "350px" },
        }}
      >
        {/* <Box
          className={"w-full flex justify-center items-center my-3"}
          sx={{ textAlign: "center", paddingTop: "8px" }}
        >
          <img
            src="/images/egpt-logo-wide-v2.png"
            alt="logo"
            className="sm:w-[250px] w-[150px]"
            style={{ height: "auto" }}
          />
        </Box> */}

        <Box
          sx={{
            maxHeight: buf,
            overflowY: "auto",
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {sections.map((_item) => {
            return (
              <SidebarSection key={_item.key} item={_item}></SidebarSection>
            );
          })}
          <Divider />
          <FavGptsList />
        </Box>
        <ProfileMenu sidebar={true} />
      </Paper>
    </>
  );
};

export default SideBar;
