import { Button, Dialog, DialogBody, Spinner } from "@material-tailwind/react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { setSessionExpired, useMainController } from "../../context";
import { httpMushPro } from "../../services/http-common";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineClose } from "react-icons/md";
import { showGPTDetails } from "../../store/bot/actions";

const ShowGptDetails = (props) => {
  const { uniqueName } = useParams();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [dispatchHttp] = useMainController();
  const { isShowingGPT } = useSelector((state) => state.bot);
  const { toogleOpen, gptUniqueName } = props;
  const [botData, setBotData] = useState(null);

  const getBotData = useMutation(
    (uniqueName) => {
      return httpMushPro.post(
        `user/getBotData/${uniqueName}`,
        {},
        null,
        setSessionExpired,
        dispatchHttp
      );
    },
    {
      onSuccess: ({ data }) => {
        setBotData(data.bot);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  const handleClose = () => {
    setBotData(null);
    toogleOpen();
  };

  const handleConservation = (botId, text) => {
    setSearchParams({ q: text });
    navigate(`/g/${botId}/?q=${encodeURIComponent(text)}`);
    handleHideModal();
  };

  const handleHideModal = () => {
    dispatch(showGPTDetails(false));
  };

  useEffect(() => {
    gptUniqueName && getBotData.mutate(gptUniqueName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gptUniqueName]);

  return (
    <>
      <Dialog size="md" open={isShowingGPT} handler={handleClose}>
        <DialogBody
          divider
          className={`grid place-items-center gap-4 ${
            !botData && "min-h-[500px]"
          }`}
        >
          {botData ? (
            <>
              <div className="w-full flex justify-end items-center">
                <MdOutlineClose
                  className="cursor-pointer"
                  onClick={handleClose}
                />
              </div>
              <div className="relative flex flex-grow flex-col gap-4 overflow-y-auto px-6 pb-2 pt-2">
                <div className="flex  flex-col items-center justify-center text-token-text-primary !h-fit">
                  <div className="relative">
                    <div className="mb-3 !h-20 !w-20">
                      <div className=" overflow-hidden rounded-full">
                        <img
                          src={botData?.iconUrl}
                          className="h-full w-full  flex bg-white items-center justify-center rounded-full border-2 border-dashed border-token-border-medium"
                          alt={botData?.botName}
                          width="80"
                          height="80"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-center gap-2">
                    <div className="text-center  ">
                      <span className="text-2xl font-semibold text-[#0d0d0d]">
                        {botData?.botName}
                      </span>
                      <div class="text-sm my-1 text-[#b7b7b7]">
                        By {botData?.coach?.firstName}
                      </div>
                    </div>

                    <div className="max-w-md text-center text-sm font-normal text-token-text-primary">
                      {botData?.primaryDomain}
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex justify-center">
                {[
                  {
                    count: botData?.reviews?.averageRating,
                    title: "Review",
                  },
                  {
                    count: botData?.tone,
                    title: "Tone",
                  },
                ].map((props, key) => (
                  <>
                    <div class="flex flex-col justify-center items-center gap-2 border-l border-token-border-heavy first:border-0 w-48 mt-4 px-2">
                      <div class="flex flex-row items-center gap-1.5 pt-1 text-xl font-semibold text-center leading-none text-black">
                        {props.title === "Review" && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            className="w-5 h-5"
                            viewBox="0 0 24 24"
                            class="icon-sm"
                          >
                            <path
                              fill="currentColor"
                              fill-rule="evenodd"
                              d="M9.822 2.077c.956-1.696 3.4-1.696 4.356 0l2.085 3.7a.5.5 0 0 0 .337.245l4.164.84c1.91.386 2.664 2.71 1.346 4.143l-2.875 3.127a.5.5 0 0 0-.128.396l.487 4.22c.224 1.934-1.753 3.37-3.524 2.56l-3.862-1.768a.5.5 0 0 0-.416 0L7.93 21.308c-1.771.81-3.748-.626-3.524-2.56l.487-4.22a.5.5 0 0 0-.128-.396L1.89 11.005C.572 9.571 1.327 7.248 3.236 6.862l4.164-.84a.5.5 0 0 0 .336-.244z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                        )}
                        {props.count ? props.count : "N/A"}
                      </div>
                      <div class="text-xs text-token-text-tertiary text-[#b7b7b7]">
                        {props.title}
                      </div>
                    </div>
                  </>
                ))}
              </div>
              {botData?.suggestions?.length > 0 && (
                <div class="flex flex-col">
                  <div class="font-bold mt-6">Conversation Starters</div>
                  <div class="mt-4 grid grid-cols-2 gap-x-1.5 gap-y-2">
                    {botData?.suggestions?.map((suggestions) => (
                      <div class="flex" tabindex="0">
                        <span class=" group relative ml-2 h-14 flex-grow rounded-xl border border-token-border-medium bg-token-main-surface-primary px-4 hover:bg-token-main-surface-secondary focus:outline-none">
                          <div class="flex h-full items-center">
                            <div class="line-clamp-2 text-sm">
                              {suggestions.question}
                            </div>
                          </div>
                          <div class="absolute -bottom-px -left-2 h-3 w-4 border-b border-token-border-medium bg-token-main-surface-primary group-hover:bg-token-main-surface-secondary">
                            <div class="h-3 w-2 rounded-br-full border-b border-r border-token-border-medium bg-token-main-surface-primary"></div>
                          </div>
                          <div
                            class="absolute bottom-0 right-2 top-0 hidden items-center group-hover:flex cursor-pointer"
                            onClick={() =>
                              handleConservation(
                                botData?.uniqueName,
                                suggestions.question
                              )
                            }
                          >
                            <div class="flex h-8 w-8 items-center justify-center rounded-lg bg-token-main-surface-primary">
                              {/* <Link
                                to={`/${
                                  botData?.uniqueName
                                }/?q=${encodeURIComponent(
                                  suggestions.question
                                )}`}
                              > */}
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon-md text-token-text-primary"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M12 4C7.58172 4 4 7.58172 4 12C4 14.1941 4.88193 16.1802 6.31295 17.6265C6.6343 17.9513 6.69466 18.4526 6.45959 18.8443L5.76619 20H12C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22H4C3.63973 22 3.30731 21.8062 3.1298 21.4927C2.95229 21.1792 2.95715 20.7944 3.14251 20.4855L4.36137 18.4541C2.88894 16.7129 2 14.4595 2 12Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                              {/* </Link> */}
                            </div>
                          </div>
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {botData?.additionalCertification && (
                <div class="flex flex-col w-full">
                  <div class="font-bold mt-6 mb-2">Certificates</div>
                  {botData?.additionalCertification
                    ?.split(", ")
                    ?.map((certificate) => (
                      <div class="flex flex-row items-start gap-2 py-1 text-sm">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon-sm mt-0.5 text-green-600"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M18.0633 5.67387C18.5196 5.98499 18.6374 6.60712 18.3262 7.06343L10.8262 18.0634C10.6585 18.3095 10.3898 18.4679 10.0934 18.4957C9.79688 18.5235 9.50345 18.4178 9.29289 18.2072L4.79289 13.7072C4.40237 13.3167 4.40237 12.6835 4.79289 12.293C5.18342 11.9025 5.81658 11.9025 6.20711 12.293L9.85368 15.9396L16.6738 5.93676C16.9849 5.48045 17.607 5.36275 18.0633 5.67387Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                        <div>{certificate}</div>
                      </div>
                    ))}
                </div>
              )}
              <div className=" mt-2">
                <div className="flex flex-grow flex-col items-center">
                  {!uniqueName && (
                    <Link
                      to={`/g/${botData?.uniqueName}`}
                      onClick={handleHideModal}
                      className="btn relative btn-primary h-12 w-full"
                    >
                      <Button
                        size="sm"
                        className="flex w-auto items-center justify-center gap-1.5"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                          className="icon-sm"
                        >
                          <path
                            fill="currentColor"
                            fill-rule="evenodd"
                            d="M12 4a8 8 0 0 0-5.687 13.627 1 1 0 0 1 .147 1.217L5.766 20H12a8 8 0 1 0 0-16M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10H4a1 1 0 0 1-.857-1.515l1.218-2.03A9.96 9.96 0 0 1 2 12"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        Start Chat
                      </Button>
                    </Link>
                  )}
                </div>
              </div>
            </>
          ) : (
            <Spinner />
          )}
        </DialogBody>
      </Dialog>
    </>
  );
};

export default ShowGptDetails;
