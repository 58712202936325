// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */
strong {
  font-weight: bold;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

h2 {
  font-size: 1.5em;
  margin: 0.75em 0;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}

li {
  margin-bottom: 10px;
}

em {
  font-style: italic;
}

a {
  color: blue;
  text-decoration: underline;
}

blockquote {
  margin: 1em 0;
  padding: 0.5em 10px;
  border-left: 5px solid #ccc;
  color: #666;
  background-color: #f9f9f9;
}

pre {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 5px;
  overflow: auto;
}

code {
  font-family: monospace;
}

.streamingTestLoader {
  background: linear-gradient(
    to right,
    #000000,
    #2e2e2e,
    #585858,
    #878787,
    #b9b9b9,
    #b9b9b9,
    #000000,
    #b9b9b9,
    #878787,
    #585858,
    #2e2e2e,
    #b9b9b9
  );
  background-size: 200%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: animate-gradient 2s linear infinite;
}

@keyframes animate-gradient {
  0% {
    background-position: right;
  }
  /*    50% {
    background-position: 100% 50%;
  } */
  100% {
    background-position: left;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/chat/style.css"],"names":[],"mappings":"AAAA,eAAe;AACf;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE;;;;;;;;;;;;;;GAcC;EACD,qBAAqB;EACrB,qBAAqB;EACrB,6BAA6B;EAC7B,oCAAoC;EACpC,8CAA8C;AAChD;;AAEA;EACE;IACE,0BAA0B;EAC5B;EACA;;KAEG;EACH;IACE,yBAAyB;EAC3B;AACF","sourcesContent":["/* styles.css */\nstrong {\n  font-weight: bold;\n}\n\nh1 {\n  font-size: 2em;\n  margin: 0.67em 0;\n}\n\nh2 {\n  font-size: 1.5em;\n  margin: 0.75em 0;\n}\n\nul {\n  list-style-type: disc;\n  margin-left: 20px;\n}\n\nli {\n  margin-bottom: 10px;\n}\n\nem {\n  font-style: italic;\n}\n\na {\n  color: blue;\n  text-decoration: underline;\n}\n\nblockquote {\n  margin: 1em 0;\n  padding: 0.5em 10px;\n  border-left: 5px solid #ccc;\n  color: #666;\n  background-color: #f9f9f9;\n}\n\npre {\n  background-color: #f4f4f4;\n  padding: 10px;\n  border-radius: 5px;\n  overflow: auto;\n}\n\ncode {\n  font-family: monospace;\n}\n\n.streamingTestLoader {\n  background: linear-gradient(\n    to right,\n    #000000,\n    #2e2e2e,\n    #585858,\n    #878787,\n    #b9b9b9,\n    #b9b9b9,\n    #000000,\n    #b9b9b9,\n    #878787,\n    #585858,\n    #2e2e2e,\n    #b9b9b9\n  );\n  background-size: 200%;\n  background-clip: text;\n  -webkit-background-clip: text;\n  -webkit-text-fill-color: transparent;\n  animation: animate-gradient 2s linear infinite;\n}\n\n@keyframes animate-gradient {\n  0% {\n    background-position: right;\n  }\n  /*    50% {\n    background-position: 100% 50%;\n  } */\n  100% {\n    background-position: left;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
