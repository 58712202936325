// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.youtube {
  width: 350px;
  height: 710px;
  border: solid;
  border-radius: 25px;
}

.faqsDiv::-webkit-scrollbar {
  width: 0;
}

@media screen and (min-width: 600px) {
  .youtube {
    width: 58.5%;
    height: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/assets/main.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,QAAQ;AACV;;AAEA;EACE;IACE,YAAY;IACZ,YAAY;EACd;AACF","sourcesContent":[".youtube {\n  width: 350px;\n  height: 710px;\n  border: solid;\n  border-radius: 25px;\n}\n\n.faqsDiv::-webkit-scrollbar {\n  width: 0;\n}\n\n@media screen and (min-width: 600px) {\n  .youtube {\n    width: 58.5%;\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
