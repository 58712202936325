import React from "react";
import { Grid, Typography, Box, Stack } from "@mui/material";

import useStyles from "./styles/styles";

import Shape from "./components/Shape";
import { FaQuoteLeft } from "react-icons/fa";

type Props = {
  testimonials: {
    id: string;
    name: string;
    review: string;
    role?: string;
    avatar?: string;
    rating?: number;
    postDate?: Date | string | number;
  }[];
};

const Testimonials = ({ testimonials }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.sectionBox}>
      <Shape secNumber="4" />
      <Grid
        container
        justifyContent="center"
        sx={{
          textAlign: "center",
          overflow: "hidden",
          py: { xs: 10, md: 15 },
        }}
      >
        <Grid xs={12} md={6}>
          <Stack spacing={2} sx={{ textAlign: "center", mb: 3 }}>
            <Typography variant="overline" sx={{ color: "text.disabled" }}>
              Testimonials
            </Typography>

            <Typography variant="h4" fontWeight={"bold"}>
              What Our Customer Say
            </Typography>
          </Stack>
          {testimonials.map((testimonial) => (
            <>
              <Stack key={testimonial.id} alignItems="center">
                <FaQuoteLeft className="w-[30px] h-[30px] text-[#000080]" />

                <Typography
                  sx={{
                    mt: 2,
                    mb: 2,
                    lineHeight: 1.75,
                    fontSize: { xs: 20, md: 16 },
                    // fontFamily: (theme) => theme.typography.h1.fontFamily,
                  }}
                >
                  {testimonial.review}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: "bold",
                    // fontFamily: (theme) => theme.typography.h1.fontFamily,
                  }}
                >
                  {testimonial.name}
                </Typography>
              </Stack>
            </>
          ))}

          {/* {testimonials.map(
              (testimonial, index) =>
                selected === index && (
                  <Stack key={testimonial.id} spacing={0.5}>
                    <Typography variant="h6">{testimonial.name}</Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      {testimonial.role}
                    </Typography>
                  </Stack>
                )
            )} */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Testimonials;
