import { Grid, Typography, Box } from "@mui/material";

import useStyles from "./styles/styles";
import BotImg from "../../assets/images/Saif.png";
import Shape from "./components/Shape";

const SaifArchitecture = () => {
  const classes = useStyles();

  const clases = {
    header2: {
      fontSize: { xs: "7.63vw", md: "3.18vw !important" },
      fontWeight: "600 !important",
      color: "#101010 !important",
      mb: "3vw",
      py: "1vw",
    },
    header3: {
      fontSize: { xs: "7.63vw", md: "2.6vw !important" },
      fontWeight: "600 !important",
      color: "#101010 !important",
    },
    header4: {
      fontSize: { xs: "5.09vw !important", md: "2.08vw !important" },
      fontWeight: "600 !important",
      // letterSpacing: "-1.33px !important",
      color: "#101010 !important",
      textAlign: "center",
    },
    contentText: {
      fontSize: { xs: "4.07vw !important", md: "1.15vw !important" },
      fontWeight: "500 !important",
      color: "#757B8A !important",
      textAlign: "center",
      // textAlign: { xs: 'center', md: 'left' },
      py: "1vw",
    },
    buttonSx: {
      width: { md: "13.22vw", xs: "66.16vw" },
      fontSize: { xs: "3.56vw", md: "1.11vw" },
      paddingY: { xs: "5vw", md: "1.5vw" },
      my: { xs: "3vw", md: "0" },
    },
    readmoreText: {
      fontSize: { xs: "4.07vw !important", md: "1.2vw !important" },
      fontWeight: "500 !important",
      color: "#000080 !important",
      textAlign: "left",
    },
  };
  return (
    <Grid className={classes.sectionBox} sx={{ background: "#F7F9FF" }}>
      <Shape secNumber="6" />
      <Grid
        item
        container
        xs={12}
        md={12}
        className={classes.flexCenter}
        sx={{
          flexDirection: "column",
          py: { xs: "15.77vw", md: "3vw" },
        }}
      >
        <Typography sx={clases.header2}>SAIF Architecture </Typography>
        <Grid
          item
          xs={11}
          md={8.7}
          className={classes.flexCenter}
          sx={{
            flexDirection: "column",
          }}
        >
          <Box>
            <Typography sx={clases.contentText}>
              Our Seeker-to-AI-to-Facilitator (SAIFE) uses AI chatbots in the
              middle to protect Seeker and Coach confidentiality. No personal
              information will be released while browsing different coaching
              options. Once the Seeker is happy with the coach's bot, the AI
              assistant chatbot agent can book appointments and do many other
              tasks on behalf of the coach.
            </Typography>
          </Box>
          <Box
            component="img"
            src={BotImg}
            alt="logo"
            className={`${classes.image} !w-[30rem] !h-auto`}
            sx={{ py: { md: "0", xs: "17.3vw" } }}
          />
          <Grid item xs={11} md={10}>
            <ul>
              <li>
                <Typography sx={clases.contentText}>
                  1. <b>Private Large Language Models (pLLMs)</b>: EntheoGPT
                  offers Seekers a choice between public LLMs like ChatGPT and
                  Gemini or unbiased self-hosted private models like Llama3 or
                  Dolphin.
                </Typography>
              </li>
              <li>
                <Typography sx={clases.contentText}>
                  2. <b>EntheoID</b> is our secure single sign on (SSO) member
                  management platform powered by Keycloak. We request minimal
                  information from members to mitigate the possibility of any
                  issue related to data breaches.
                </Typography>
              </li>
              <li>
                <Typography sx={clases.contentText}>
                  3. <b>Data Privacy</b> is ensured by allowing members to
                  delete their chat history at any time. Chat histories are
                  periodically stripped of any personally identifiable
                  information (PII), summarized for quality control and then
                  deleted for security.
                </Typography>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SaifArchitecture;
