// @mui
import { Skeleton, Stack, Card, Divider, Box, CardProps } from "@mui/material";

// ----------------------------------------------------------------------

export default function GptsStoreSkeleton({ ...other }: CardProps) {
  return (
    <Card
      {...other}
      sx={{
        border: "1px solid rgb(46 38 61 / 0.12)",
        boxShadow: "none",
      }}
    >
      <Stack spacing={2} sx={{ p: 3 }}>
        <Skeleton variant="circular" width={48} height={48} />

        {[...Array(2)].map((_, index) => (
          <Skeleton
            key={index}
            variant="text"
            sx={{
              height: 20 - index * 2,
              width: (5 - index) * 50,
            }}
          />
        ))}
      </Stack>

      <Divider sx={{ borderStyle: "dashed" }} />

      <Box
        sx={{
          p: 3,
          gap: 2,
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
        }}
      >
        {[...Array(3)].map((_, index) => (
          <Skeleton
            key={index}
            variant="rounded"
            sx={{ width: "70px", height: 20, borderRadius: 10 }}
          />
        ))}
      </Box>
    </Card>
  );
}
